<template>
    <modal ref="modalDevolverProducto" titulo="Devolver producto al cedis" icon="devolver_productos" tamano="modal-lg" no-aceptar adicional="Devolver" @adicional="devolver">
        <cargando v-if="cargando" />
        <div class="row mx-0 text-general justify-center mb-4">
            Seleccione los productos a devolver
        </div>
        <div v-for="(p,index) in listado_productos" :key="index" class="row mx-0 mb-4">
            <div class="col-6 pr-0">
                <div class="row mx-0">
                    <div class="col-auto d-middle">
                        <el-checkbox v-model="p.check" class="check-red" />
                    </div>
                    <div class="col-9 pr-0 d-flex">
                        <img :src="p.imagen" width="60px" height="60px" class="obj-cover" />
                        <div class="col d-flex flex-column justify-content-around">
                            <p class="text-general f-13 nombre-producto">
                                {{ p.nombre }}
                            </p>
                            <p class="text-general2 f-12">
                                {{ p.presentacion }}
                            </p>
                            <div class="row mx-0">
                                <p class="col px-0 text-general tres-puntos f-12">
                                    {{ convertMoney(p.valor_unitario,pedido.idm_moneda) }}
                                </p>
                                <p class="col text-center px-0 tres-puntos text-general f-12">
                                    <span>{{ p.cantidad_maxima }}</span> Uni.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div v-show="p.check" class="row mx-0 h-100 align-items-end">
                    <div class="col-5">
                        <p class="text-general f-15 pl-2"> Unidades </p>
                        <el-input-number
                        v-model="p.cantidad" size="small"
                        class="transparent-input"
                        :min="p.salto"
                        :max="p.cantidad_maxima"
                        :step="p.salto"
                        :step-strictly="p.entero"
                        :precision="p.presicion"
                        />
                    </div>
                    <div class="col-7 px-1">
                        <p class="text-general f-15 pl-2">Motivo de devolucion</p>
                        <ValidationProvider v-slot="{errors}" :rules="{required:p.check}" name="motivo">
                            <el-select v-model="p.id_motivo" placeholder="Seleccione Motivo" size="small" class="w-100">
                                <el-option
                                v-for="item in motivos"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <p class="text-danger pl-2 w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    props:{
        pedido:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            cargando: false,
            motivos:[],
            listado_productos:[],
            id_pedido:null
        }
    },
    methods: {
        toggle(productos,id_pedido){
            this.cargando = false;
            this.id_pedido = id_pedido
            this.select_motivos()
            this.armar_array(productos)
            this.$refs.modalDevolverProducto.toggle();
        },
        armar_array(array){
            let productos = []
            for (const p of array){
                productos.push({
                    id:p.producto.id,
                    nombre:p.producto.nombre,
                    imagen:p.producto.imagen,
                    valor_unitario:p.unidad_final,
                    cantidad:parseFloat(p.cantidad),
                    cantidad_maxima:parseFloat(p.cantidad),
                    presentacion:p.producto.presentacion,
                    id_motivo:null,
                    check:false,
                    entero:p.producto.cantidad_tipo === 1,
                    presicion:p.producto.cantidad_tipo === 1 ? 0 : 2,
                    salto:parseFloat(p.producto.cantidad_minima)
                })
            }
            this.listado_productos = productos
        },
        async select_motivos(){

            const {data} = await axios(`motivos/lista-motivos?tipo=12`)
            this.motivos = data.motivos
        },
        async devolver(){
            if(this.cargando === true)return
            this.cargando = true;
            try {

                let productos = []
                for (const p of this.listado_productos){
                    if(p.check == false) continue

                    if(p.id_motivo == null){
                        this.notificacion('Alerta','Por favor seleccione un motivo de devolución','warning')
                        this.cargando = false;
                        return
                    }

                    productos.push({
                        id_producto:p.id,
                        cantidad_devuelta:p.cantidad,
                        id_motivo:p.id_motivo
                    })
                }

                if(productos.length == 0) return

                const {data} = await Pedidos.devolucion(this.id_pedido,productos)
                this.$emit('update')
                this.notificacion('Éxito','Devolucion realizada exitosamente','success')
                this.$refs.modalDevolverProducto.toggle();
                this.cargando = false;

            } catch (e){
                this.cargando = false;
                this.error_catch(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.nombre-producto{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
